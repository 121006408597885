<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="handleBack()"
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />
      <h1 class="text-xl text-left font-extrabold ml-4 mt-3 w-auto">
        <span v-if="payrunData.type !== 'off-cycle' && payrunData.month">
          {{
            `${$DATEFORMAT(
              new Date(payrunData.year, payrunData.month - 1, 1),
              "LLLL"
            )}
              ${payrunData.year}`
          }}
          Pay Cycle
        </span>
        <span v-else>
          Off-Cycle Pay
        </span>
      </h1>
      <div v-if="payrunData.type === 'off-cycle'" class="w-7/12 mt-4 ml-8">
        <Breadcrumb :items="breadcrumbsOffCycle" />
      </div>
      <div v-else class="w-7/12 mt-4 ml-8">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div style="height: 100%" v-if="loadingData">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <transition name="fade">
        <span
          :class="`w-1/4 shadow-lg p-3 text-white
        text-sm text-center absolute ${responseBg}`"
          style="left: 72%; top: 18%"
          v-if="response != ''"
        >
          {{ response }}
        </span>
      </transition>

      <div class="px-3">
        <card class="p-5 mt-6">
          <div class="w-full mb-4 flex flex-col gap-5 border border-dashed border-romanSilver rounded p-3">
            <div class="w-full flex justify-between items-center">
              <TrendToolTip :is-trend-tool-tip="isTrendToolTip"
                @on-enter=" isTrendToolTip = true"
                @on-leave=" isTrendToolTip = false"
              />
              <p @click="isTrend = !isTrend"
                class="w-1/3 cursor-pointer text-right text-blueCrayola font-semibold text-sm"
              >
                  {{isTrend ? `Collapse Trend` : `Expand Trend`}}
              </p>
            </div>
            <div v-if="isTrend" class="gridContainer">
              <TrendState
                type="Last month"
                :amount="payrunTrend ? payrunTrend.previousPayrun.grossPay : 0"
                :net-amount="payrunTrend ? payrunTrend.previousPayrun.netPay : 0"
                :emp-count="payrunTrend ? payrunTrend.previousPayrun.empCount : 0"
                icon-name="blue-gross-icon"
              />

              <TrendState
                type="Current month"
                :amount="payrunTrend ? payrunTrend.currentPayrun.grossPay : 0"
                :net-amount="payrunTrend ? payrunTrend.currentPayrun.netPay : 0"
                :emp-count="payrunTrend ? payrunTrend.currentPayrun.empCount : 0"
                icon-name="yellow-gross-icon"
              />

              <TrendState
                type="Variance"
                :amount="payrunTrend ? payrunTrend.variance.grossPay : 0"
                :net-amount="payrunTrend ? payrunTrend.variance.netPay : 0"
                :emp-count="payrunTrend ? payrunTrend.variance.empCount : 0"
                icon-name="green-gross-icon"
                :trend="growthTrend"
              />
            </div>
          </div>
          <div class="w-full h-auto p-3 rounded" style="border: 1px dashed #878E99">
            <p class="font-bold text-lg mb-4 ">Pay Run Settings</p>
            <div class="w-full flex flex-wrap justify-start items-center gap-2">
              <card class="p-3 w-204">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  Pay run Type
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase flex flex-col justify-start">
                  <span>{{ payrunData.payType }}</span>
                  <span v-if="payrunData.type === 'off-cycle'" class="text-blueCrayola text-10">
                    {{ payrunData.type }}
                  </span>
                </h6>
              </card>
              <card class="p-3 w-204">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                   Payment frequency:
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{
                    payrunData.payFrequency === "twice_monthly"
                      ? "Twice Monthly"
                      : payrunData.payFrequency === "weekly"
                      ? "Four Times Monthly"
                      : payrunData.payFrequency
                  }}
                </h6>
              </card>
              <card class="p-3 w-204">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  Payment Schedule:
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{ payrunData.paySplitPosition ? `${$getOrdinal(payrunData.paySplitPosition)} Payment` : "NA" }}
                </h6>
              </card>
              <card class="p-3 w-204" v-if="payrunData.payrollStatus === 'paid'">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  pay date
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{
                    payrunData.paidAt
                      ? `${$DATEFORMAT(
                          new Date(payrunData.paidAt),
                          "MMMM dd, yyyy"
                        )}`
                      : "---"
                    }}
                </h6>
              </card>
              <card class="p-3 w-204" v-else>
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  pay date
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{ payrunData.createdAt
                    ? `${$DATEFORMAT(
                      new Date(payrunData.createdAt),
                      "MMMM dd, yyyy"
                    )}` : "---"
                  }}
                </h6>
              </card>
              <card class="p-3 w-204">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  initiated by
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{ payrunData.initiatedBy ? payrunData.initiatedBy : "---"}}
                </h6>
              </card>
              <card class="p-3 w-204">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  approved by
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{ payrunData.approval ? payrunData.approval : "---"}}
                </h6>
              </card>
              <card class="p-3 w-204">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  paid by
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{ payrunData.paidBy ? payrunData.paidBy : "---"}}
                </h6>
              </card>
              <card class="p-3 w-204">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  pay reference #
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  CL16282637272
                </h6>
              </card>
              <card class="p-3 w-204">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  total employees
                </h6>
                <h6 v-if="payrunData.type === 'off-cycle'" class="font-semibold text-sm text-jet uppercase">
                  {{ tableData ? tableData.length : 0 }}
                </h6>
                <h6 v-else class="font-semibold text-sm text-jet uppercase">
                  {{ selectedEmployees }} of {{ tableData ? tableData.length : 0 }}
                </h6>
              </card>
              <card class="p-3 w-204">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  success rate
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{ payrunData.successRate ? payrunData.successRate : "---"}}
                </h6>
              </card>
              <card class="p-3 w-204 relative"
                v-if="
                  payrunData.payrollStatus === 'approved' ||
                  payrunData.payrollStatus === 'paid' ||
                  payrunData.payrollStatus === 'review' ||
                  payrunData.payrollStatus === 'disapproved'
                "
              >
                <div @click="isTotalGross = true"
                  class="w-auto h-auto absolute top-3 right-3 cursor-pointer"
                >
                  <icon
                    icon-name="icon-eye"
                    class-name="text-blueCrayola"
                    size="xs"
                  />
                </div>
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  total Gross pay
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                    {{ convertToCurrency(employeeGrossPay) }}
                </h6>
              </card>
              <card class="p-3 w-204 relative"
                v-if="
                  payrunData.payrollStatus === 'approved' ||
                  payrunData.payrollStatus === 'paid' ||
                  payrunData.payrollStatus === 'review' ||
                  payrunData.payrollStatus === 'disapproved'
                "
              >
                <div @click="isTotalGross = true"
                  class="w-auto h-auto absolute top-3 right-3 cursor-pointer"
                >
                  <icon
                    icon-name="icon-eye"
                    class-name="text-blueCrayola"
                    size="xs"
                  />
                </div>
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  total net pay
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                    {{ convertToCurrency(totalNetPay) }}
                </h6>
              </card>
            </div>
          </div>
          <div class="w-full flex justify-end my-5">
            <div class="flex my-auto">
              <Button
                background-color="none"
                class="text-white bg-dynamicBackBtn mr-5 rounded"
                v-if="payrunData.payrollStatus === 'paid'"
                @click="canExport"
              >
                Export csv
              </Button>
              <Button
                class="bg-white mr-3 text-dynamicBackBtn border border-solid border-dynamicBackBtn"
                style-name="width:inherit"
                options
                v-if="payrunData.payrollStatus === 'approved'"
                :option-list="['Pay Offline', 'Cancel Pay Run']"
                @click="checkOption($event)"
              >
                Payment Options
              </Button>
              <Button
                background-color="none"
                class="text-white bg-dynamicBackBtn mr-5 rounded"
                v-if="payrunData.payrollStatus === 'approved'"
                @click="paymentPrivilege"
              >
                Pay
              </Button>
            </div>
          </div>
          <div class="border w-full bg-romanSilver mt-8" />
        </card>

        <div class="w-full" v-if="approvalsData.requestId === $route.params.id">
          <card class="p-3 mt-4">
            <div class="my-6" style="border: 0.2px solid #878e99" />
            <div class="w-full flex">
              <div class="w-3/5 flex">
                <div class="w-auto flex py-3 px-3">
                  <div class="flex w-full">
                    <img
                      v-if="approvalsData.photo != null"
                      class="mr-2"
                      alt="photo"
                      style="width: 52px; height: 52px"
                      :src="approvalsData.photo"
                    />
                    <div
                      style="height: 52px; width: 52px; border-radius: 5px"
                      class="text-blueCrayola border text-center font-semibold pt-2 mr-2"
                      v-else
                    >
                      {{
                        $getInitials(
                          `${approvalsData.fname} ${approvalsData.lname}`
                        )
                      }}
                    </div>
                    <div class="flex flex-col">
                      <p class="text-base font-semibold text-romanSilver">
                        Submitted By:
                      </p>
                      <div class="flex">
                        <span class="text-lg text-optimaBlack font-bold mr-3">
                          {{ `${approvalsData.fname} ${approvalsData.lname}` }}
                        </span>
                        <span class="text-lg text-blueCrayola font-bold">
                          {{ `${approvalsData.userDesignation.name}` }}
                        </span>
                      </div>
                    </div>
                    <div class="border h-10 mx-5 mt-4" style="width: 1px" />
                    <div class="flex">
                      <span>
                        <icon
                          icon-name="icon-message-outline"
                          size="xs"
                          class="mt-7"
                        />
                      </span>
                      <span
                        class="text-flame text-base font-semibold mt-6 ml-4"
                      >
                        Send Message
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="flex ml-4 py-3">
                  <img
                    v-if="
                      approverDetails.photo != null &&
                      approverDetails.photo != ''
                    "
                    class="mr-2"
                    alt="photo"
                    style="width: 80px; height: 80px"
                    :src="approverDetails.photo"
                  />
                  <div
                    style="height: 80px; width: 80px; border-radius: 5px"
                    class="text-blueCrayola border text-center font-semibold pt-2 mr-2"
                    v-else
                  >
                    {{
                      $getInitials(
                        `${approverDetails.fname} ${approverDetails.lname}`
                      )
                    }}
                  </div>
                  <div class="flex flex-col">
                    <p class="text-lg font-bold text-darkPurple">
                      {{ `${approverDetails.fname} ${approverDetails.lname}` }}
                    </p>
                    <span class="flex my-1">
                      <p
                        class="font-semibold uppercase text-romanSilver mr-3 mt-1"
                        style="font-size: 10px"
                      >
                        Designation:
                      </p>
                      <p class="font-semibold uppercase text-sm text-jet">
                        {{ approverDetails.userDesignation.name }}
                      </p>
                    </span>
                    <span class="flex my-1">
                      <p
                        class="font-semibold uppercase text-romanSilver mr-3 mt-1"
                        style="font-size: 10px"
                      >
                        Department:
                      </p>
                      <p class="font-semibold uppercase text-sm text-jet">
                        {{ approverDetails.orgFunction.name }}
                      </p>
                    </span>
                    <span class="flex my-1">
                      <p
                        class="font-semibold uppercase text-romanSilver mr-3 mt-1"
                        style="font-size: 10px"
                      >
                        Office Location:
                      </p>
                      <p class="font-semibold uppercase text-sm text-jet">
                        {{ approverDetails.employeeOffice.name }}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </card>
          <card
            class="p-5 mt-4"
            v-if="approvalsData.requestId === $route.params.id"
          >
            <div class="w-full flex">
              <div class="flex flex-col flex-grow py-2">
                <p class="text-lg font-bold">
                  Approvers ({{ approversNumber }})
                </p>
                <p class="text-sm">
                  You can quickly approve or dissaprove from here.
                </p>
                <p class="text-sm">
                  You also see other approvers’ responses and comments within
                  the approval trail.
                </p>
              </div>
              <div class="flex flex-grow flex-col">
                <span class="flex">
                  <p class="font-bold text-darkPurple text-xl">
                    {{ approvalsData.requests.approvalPercentage }}%
                  </p>
                  <p class="text-romanSilver ml-1 mt-1 text-sm">Completed</p>
                </span>
                <ProgressBar
                  :value="approvalsData.requests.approvalPercentage"
                  class="mt-2"
                />
                <p
                  class="font-semibold text-xs mt-2 text-romanSilver uppercase"
                >
                  {{ approvalsData.requests.approversLeft }} approvers left
                </p>
              </div>
              <div class="flex flex-grow justify-end mt-2">
                <Button
                  class="text-dynamicBackBtn border border-solid border-dynamicBackBtn font-semibold text-center"
                  width="8rem"
                  style="height: 44px"
                  @click="displayProgress()"
                >
                  See Comments
                </Button>
              </div>
            </div>
          </card>
        </div>
        <card class="mt-5">
          <sTable
            :headers="headers"
            :items="tableData"
            style="width: 100%; height: auto"
            class="w-full"
            aria-label="table"
            :has-number="payrunData.type !== 'off-cycle'"
            :loading="false"
            :pagination-list="metaData"
            @page="handlePage($event)"
            page-sync
            @itemsPerPage="handleItemsPerPage($event)"
          >
            <template v-slot:item="{ item }">
              <div v-if="item.photo" class="flex items-center gap-3">
                <div v-if="isPresent" class="rounded-full sideDot1"></div>
                <div v-else class="rounded-full sideDot2"></div>
                <img
                  class=""
                  :src="item.data.photo"
                  alt="photo"
                  v-if="item.data.photo"
                  style="height: 30px; width: 30px; border-radius: 5px"
                />
                <div
                  style="height: 35px; width: 35px; border-radius: 5px"
                  class="text-blueCrayola border text-center font-semibold pt-2"
                  v-else
                >
                  {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
                </div>
              </div>

              <span
                class="tw-font-bold"
                style="color: #525252"
                v-else-if="item.fname"
              >
                {{ item.data.fname }} {{ item.data.lname }}
              </span>
              <span v-else-if="item.regularEarnings" class="tw-text-center">
                {{
                  !item.data.regularEarnings
                    ? "Nil"
                    : convertToCurrency(item.data.regularEarnings)
                }}
              </span>
              <span v-else-if="item.additions" class="tw-text-center">
                <Menu
                  right
                  top="-300"
                  margin="46"
                  class="my-2 p-0"
                  v-if="item.data.additions"
                >
                  <template v-slot:title>
                    <div class="underline text-blueCrayola">
                      {{ convertToCurrency(item.data.additions) }}
                    </div>
                  </template>
                  <div style="width: 250px; padding: 10px 5px">
                    <scroll-container height="200px">
                      <div
                        class="flex flex-col py-2 px-3"
                        v-for="(additions, index) in item.data
                          .monthlyAdditionsPayItems"
                        :key="index"
                      >
                        <p class="pt-1 flex flex-grow text-sm font-semibold">
                          {{ additions.name }}
                        </p>
                        <p class="pt-1 flex text-xs text-romanSilver">
                          {{ convertToCurrency(additions.amount) }}
                        </p>
                      </div>
                    </scroll-container>
                  </div>
                </Menu>
                <span v-else> 0 </span>
              </span>
              <span v-else-if="item.deductions" class="tw-text-center">
                <Menu
                  right
                  top="-300"
                  margin="54"
                  class="my-2 p-0"
                  v-if="item.data.deductions"
                >
                  <template v-slot:title>
                    <div class="underline text-desire">
                      ({{ convertToCurrency(item.data.deductions) }})
                    </div>
                  </template>
                  <div style="width: 250px; padding: 10px 5px">
                    <scroll-container height="200px">
                      <div
                        class="flex flex-col py-2 px-3"
                        v-for="(deductions, index) in item.data
                          .monthlyDeductionsPayItems"
                        :key="index"
                      >
                        <p class="pt-1 flex flex-grow text-sm font-semibold">
                          {{ deductions.name }}
                        </p>
                        <p class="pt-1 flex text-xs text-romanSilver">
                          {{ convertToCurrency(deductions.amount) }}
                        </p>
                      </div>
                    </scroll-container>
                  </div>
                </Menu>
                <span v-else> 0 </span>
              </span>
              <span v-else-if="item.monthlyPAYE" class="tw-text-center">
                {{
                  item.data.monthlyPAYE
                    ? convertToCurrency(item.data.monthlyPAYE)
                    : 0
                }}
              </span>
              <span v-else-if="item.employeePension" class="tw-text-center">
                {{
                  item.data.employeePension
                    ? convertToCurrency(item.data.employeePension)
                    : 0
                }}
              </span>
              <span v-else-if="item.nhfAmount" class="tw-text-center">
                {{
                  item.data.nhfAmount
                    ? convertToCurrency(item.data.nhfAmount)
                    : 0
                }}
              </span>
              <span v-else-if="item.status" class="tw-text-center">
                <p :class="`${colorText(item.data.status)} capitalize`">
                  {{ item.data.status }}
                </p>
              </span>
              <span v-else-if="item.netPay" class="grosspay">
                {{ convertToCurrency(item.data.netPay) }}
              </span>
            </template>
          </sTable>
        </card>
        <div
          class="flex flex-col items-center mt-4"
          v-if="approverDetails.userId === $AuthUser.id"
        >
          <div class="w-full flex">
            <CTextarea
              placeholder="Insert Comment Here"
              variant="primary"
              class="mt-2"
              v-if="!hasSubscriptionExpired()"
              value=""
              :row="8"
              :height="90"
              :col="135"
              v-model="comment"
            />
          </div>
          <div v-if="!hasSubscriptionExpired()" class="w-full flex mt-4 mb-5">
            <Button
              background-color="none"
              class="text-white mr-5 bg-dynamicBackBtn rounded"
              v-if="payrunData.payrollStatus === 'review'"
              @click="submitApproval('Approved')"
            >
              Approve Payrun
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn"
              v-if="payrunData.payrollStatus === 'review'"
              @click="submitApproval('Disapproved')"
            >
              Disapprove Payrun
            </Button>
          </div>
        </div>

        <div
          v-if="payrunData.payrollStatus === 'draft'"
          class="flex flex-col items-center mt-4"
        >
          <div class="w-full flex mt-4 mb-5">
            <Button
              background-color="none"
              class="text-white mr-5 bg-dynamicBackBtn rounded"
              @click="edit()"
            >
              Submit Payrun
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn"
              @click="deleteModal($route.params.id)"
            >
              Delete Payrun
            </Button>
          </div>
        </div>
      </div>
    </div>
    <RightSideBar
      v-if="openModal"
      @submit="submit"
      @close="openModal = false"
      button-class="bg-dynamicBackBtn text-white"
      close-button
    >
      <template v-slot:title>
        <p class="text-darkPurple">Action</p>
      </template>
      <template v-slot:subtitle>
        <p class="font-semibold text-darkPurple text-l mb-2">
          Confirm your action here. You can add a comment or remark based on
          what action is taken.
        </p>
        <p class="text-jet text-l mb-2">You can change selected action here:</p>
        <RadioButton
          :options="categoryArray"
          col-span="col-span-12"
          class="m-4 text-darkPurple"
          row-gap="gap-y-3"
          v-model="ActionType"
        />
      </template>
      <p class="text-darkPurple text-base font-semibold">Add Comment/Remark</p>
    </RightSideBar>

    <RightSideBar v-if="seeComments" @close="seeComments = false" close-button>
      <template v-slot:title>
        <p class="text-darkPurple">Approvers ({{ approversNumber }})</p>
      </template>
      <template v-slot:subtitle>
        <p class="font-semibold text-darkPurple pb-4">
          See other approvers comments to this request.
        </p>
      </template>
      <div>
        <div v-for="(item, index) in requestProgress" :key="index">
          <ApproversCard
            class="flex bg-white pt-2 px-3 pb-4 mb-2 even:bg-ghostWhite border-l-4"
            :style="approveDetails(item.currentApprover)"
          >
            <div class="flex w-full">
              <div class="flex w-auto pr-1 py-2 inline-block">
                <img
                  :src="item.photo"
                  class="round-md"
                  alt="photo"
                  style="height: 40px; width: 40px"
                />
              </div>
              <div class="flex flex-col w-3/4 mt-2">
                <div class="leading-tight">
                  <span class="block text-lg leading-tight">
                    <span class="block text-base"
                      >{{ item.fname }} {{ item.lname }}</span
                    >
                    <span class="text-romanSilver uppercase text-xs block">
                      {{ item.userDesignation.name }}
                    </span>
                    <span class="block text-xs">{{
                      $DATEFORMAT(new Date(item.actionTakenAt), "MMMM dd, yyyy")
                    }}</span>
                  </span>
                </div>
                <div class="flex w-full mt-1 leading-tight">
                  <div class="w-full">
                    <span
                      class="pr-2 text-sm break-words leading-tight"
                      v-if="item.comment != ''"
                    >
                      <span class="font-normal text-romanSilver leading-tight">
                        Comment:
                      </span>
                      <span class="leading-tight">
                        {{ item.comment }}
                      </span>
                    </span>
                    <span class="leading-tight" v-else> --No Comment-- </span>
                  </div>
                </div>
              </div>
              <div class="flex-none w-4/12 text-base">
                <Tag
                  class="py-2 px-2 mx-2 float-right mt-8 text-sm"
                  :class="approveBgColor(item.currentApprover)"
                >
                  {{ approveText(item.currentApprover) }}
                </Tag>
              </div>
            </div>
          </ApproversCard>
        </div>
      </div>
    </RightSideBar>

    <Modal v-if="showOfflineModal">
      <Card class="p-5 flex flex-col max-w-lg">
        <div class>
          <h4 class="mb-6 text-romanSilver">
            Pay Offline through any other means. Providing details of your
            offline payments helps the system properly account for your payroll
            costs.
          </h4>
          <div class="flex justify-end">
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn"
              width="96px"
              @click="showOfflineModal = false"
            >
              Cancel
            </Button>
            <Button
              width="96px"
              class="ml-4 text-white"
              :background="appearance.buttonColor || defaultBTNColor"
              @click="handlePayOffline"
            >
              Proceed
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
    <RightSideBar
      v-if="showOfflineSidebar"
      @close="showOfflineSidebar = false"
      submit="Submit"
      @submit="payOffline"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple">Pay Offline</p>
      </template>
      <template v-slot:subtitle>
        <p class="text-base font-semibold mb-4">
          Providing details of your offline payments helps the system properly
          account for your payroll costs.
        </p>
      </template>
      <p class="text-jet text-sm mr-5">Effective Date</p>
      <div class="date-flex">
        <datepicker
          placeholder="--Start Date--"
          input-class="date-input "
          style="width: 100%"
          v-model="offline.paidAt"
        />
      </div>
      <div class="mt-4">
        <c-select
          placeholder="--Select--"
          :options="banks"
          variant="w-full"
          label="Select Bank (Optional)"
          v-model="offline.paymentSource"
        />
      </div>
      <div class="mt-4">
        <c-text
          placeholder="--Enter--"
          variant="w-full"
          label="Transaction ID (Optional)"
          v-model="offline.paymentTransactionId"
        />
      </div>
    </RightSideBar>
    <cancel-modal
      :show-cancel-modal="showCancelModal"
      @cancelModal="handleCancelModal"
      @canceled="cancelApproval"
      :loading="loadingData"
      :pay-run-data-id="payrunData.id"
    />
    <Modal v-if="delModal">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to delete this Payrun?
          </p>
          <div class="flex">
            <Button
              class="text-white"
              :background="appearance.buttonColor || defaultBTNColor"
              @click="deletePayrun"
            >
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="delModal = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  
    <ProceedActionModal v-if="confirmModal"
      @proceed="handleConfirm"
      @cancel="confirmModal = false"
      title="Are you sure you want to make this payment?"
    />
    
    <Modal v-if="showPasswordModal">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-extrabold text-lg my-5 text-left">
            You need to be authenticated to make payment.
          </p>
          <p class="text-romanSilver font-semibold text-left mt-5 mb-3">
            Type in your password
          </p>
          <div class="my-5">
            <CText
              placeholder="--Enter--"
              label="Password"
              class="w-full mt-2"
              v-model="password"
              :type="decryptPassword ? '' : 'password'"
            />
            <checkbox
              label="Show Password"
              checkbox-size="width:16px; height: 16px; margin-top:4px"
              class="flex justify-start items-left my-4"
              v-model="decryptPassword"
            />
          </div>
          <div class="flex justify-end">
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn"
              width="7.875rem"
              @click="showPasswordModal = false"
            >
              Cancel
            </Button>
            <Button
              width="7.875rem"
              class="ml-4 text-white"
              :background="appearance.buttonColor || defaultBTNColor"
              :disabled="disableBtn"
              @click="checkPassword"
            >
              Submit
            </Button>
          </div>
        </div>
      </Card>
    </Modal>

    <RightSideBar
      v-if="openTransactionModal"
      @close="openTransactionModal = false"
      close-button
    >
      <template v-slot:title>
        <p class="text-darkPurple">Breakdown of Charges</p>
      </template>
      <template v-slot:subtitle>
        <p class="text-base font-semibold mb-4">
          The payment service provider charges a fee per employee processed. The
          amount displayed is the total charged for this transaction.
        </p>
      </template>
      <div class="flex flex-col">
        <div class="flex flex-col bg-ghostWhite p-4 mt-5">
          <div class="flex">
            <p class="w-1/2 mb-2 text-romanSilver uppercase text-xs font-black">
              employee pay range
            </p>
            <p class="w-1/2 mb-2 text-romanSilver uppercase text-xs font-black">
              applicable charge
            </p>
          </div>
          <div class="flex mt-2">
            <div class="w-1/2 flex">
              <icon
                icon-name="icon-check-square"
                class="xsm mr-2"
                color="#13B56A"
              />
              <p class="text-sm font-semibold">₦ 5,000 and below</p>
            </div>
            <div class="flex w-1/2">
              <p class="text-sm font-semibold">₦ 10 per employee</p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="flex flex-col bg-ghostWhite p-4 mt-5">
          <div class="flex">
            <p class="w-1/2 mb-2 text-romanSilver uppercase text-xs font-black">
              employee pay range
            </p>
            <p class="w-1/2 mb-2 text-romanSilver uppercase text-xs font-black">
              applicable charge
            </p>
          </div>
          <div class="flex mt-2">
            <div class="w-1/2 flex">
              <icon
                icon-name="icon-check-square"
                class="xsm mr-2"
                color="#13B56A"
              />
              <p class="text-sm font-semibold">₦ 5,001 - ₦ 50,000</p>
            </div>
            <div class="flex w-1/2">
              <p class="text-sm font-semibold">₦ 25 per employee</p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="flex flex-col bg-ghostWhite p-4 mt-5">
          <div class="flex">
            <p class="w-1/2 mb-2 text-romanSilver uppercase text-xs font-black">
              employee pay range
            </p>
            <p class="w-1/2 mb-2 text-romanSilver uppercase text-xs font-black">
              applicable charge
            </p>
          </div>
          <div class="flex mt-2">
            <div class="w-1/2 flex">
              <icon
                icon-name="icon-check-square"
                class="xsm mr-2"
                color="#13B56A"
              />
              <p class="text-sm font-semibold">Above ₦50,000</p>
            </div>
            <div class="flex w-1/2">
              <p class="text-sm font-semibold">₦ 50 per employee</p>
            </div>
          </div>
        </div>
      </div>
    </RightSideBar>

    <ViewTotalGross v-if="isTotalGross"
      :total-gross="employeeGrossPay"
       :total-net="totalNetPay"
        :total-org-pension="orgPension"
         :total-paye="paye"
          :total-pension="employeePension"
           :total-nhf="nhfAmount"
      @close="handleCloseTotalGross"
    />
  </div>
</template>

<script>
/* eslint-disable no-console */
import { mapState } from "vuex";

import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Card from "@/components/Card";
import Button from "@/components/Button";
import RightSideBar from "@/components/RightSideBar";
import RadioButton from "@/components/RadioButton";
import ProgressBar from "@/components/ProgressBar";
import ApproversCard from "@/components/ApproversCard";
import Icon from "@/components/Icon";
import Modal from "@/components/Modal";
import ScrollContainer from "@/components/ScrollContainer";
import Tag from "@/components/Tag";
import Menu from "@/components/Menu";
import Checkbox from "@/components/Checkbox";
import CancelModal from "./CancelModal";

export default {
  components: {
    BackButton,
    Card,
    ScrollContainer,
    Button,
    RightSideBar,
    Modal,
    CancelModal,
    Breadcrumb,
    RadioButton,
    CTextarea,
    ProgressBar,
    ApproversCard,
    Tag,
    Menu,
    Icon,
    Checkbox,
    STable,
    Datepicker: () => import("vuejs-datepicker"),
    CSelect: () => import("@scelloo/cloudenly-ui/src/components/select"),
    CText: () => import("@scelloo/cloudenly-ui/src/components/text"),
    ProceedActionModal: () => import("@/components/ProceedActionModal"),
    TrendState: () => import("@/components/TrendState"),
    ViewTotalGross: () => import("@/components/ViewTotalGross"),
    TrendToolTip: () => import("@/components/TrendToolTip"),
  },
  data() {
    return {
      isTrendToolTip: false,
      isTrend: false,
      isTotalGross: false,
      payrunTrend: null,
      growthTrend: null,
      selectedEmployees: 0,
      employeeGrossPay: 0,
      totalNetPay: 0,
      orgPension: 0,
      paye: 0,
      employeePension: 0,
      nhfAmount: 0,
      openModal: false,
      openTransactionModal: false,
      loadingData: true,
      confirmModal: false,
      delModal: false,
      metaData: {},
      itemsPerPage: 50,
      numberOfPage: 1,
      showOfflineModal: false,
      showOfflineSidebar: false,
      showCancelModal: false,
      showPasswordModal: false,
      password: "",
      disableBtn: false,
      decryptPassword: false,
      offline: {
        paidAt: "",
        paymentSource: "",
        paymentTransactionId: "",
      },
      payrunId: "",
      year: "",
      netPaySum: 0,
      paymentType: "",
      getToday: "",
      seeComments: false,
      payCycleYear: "",
      payrunData: {},
      payment: { transferedAmount: 0, totalAmount: 0, totalCharges: 0 },
      tableData: [],
      approvalsData: {},
      ActionType: "Approve pay run",
      approverDetails: {},
      requestProgress: [],
      approversNumber: "0",
      comment: "",
      response: "",
      responseBg: "",
      success: false,
      breadcrumbs: [
        {
          disabled: false,
          text: "Payroll",
          href: "payroll",
          id: "Payroll",
        },
        {
          disabled: false,
          text: "Payrun",
          href: "payrun",
          id: "Payrun",
        },
        {
          disabled: false,
          text: `Pay Cycle`,
          id: "Advance Request",
        },
      ],
      categoryArray: [
        {
          name: "Approve pay run",
          value: "Approve pay run",
          radioName: "job",
        },
        {
          name: "Reject pay run",
          value: "Reject pay run",
          radioName: "job",
        },
        {
          name: "Return for review",
          value: "Return for review",
          radioName: "job",
        },
      ],
      regularBackPayheaders: [
        { title: "", value: "photo", width: 5 },
        { title: "Employee Name", value: "fname", width: 15 },
        { title: "Regular Earnings", value: "regularEarnings", width: 12 },
        { title: "Additions", value: "additions", width: 12 },
        { title: "Deductions", value: "deductions", width: 12 },
        { title: "PAYE", value: "monthlyPAYE", width: 12 },
        { title: "Pension", value: "employeePension", width: 12 },
        { title: "NHF", value: "nhfAmount", width: 8 },
        { title: "Status", value: "status", width: 12 },
        { title: "Net Pay", value: "netPay", width: 12 },
      ],
      offCyclesheaders: [
        { title: "", value: "photo"},
        { title: "Employee", value: "fname" },
        { title: "Payment Due", value: "regularEarnings" },
        { title: "Additions", value: "additions" },
        { title: "Deductions", value: "deductions" },
        { title: "PAYE", value: "monthlyPAYE" },
        { title: "Net Pay", value: "netPay" },
      ],
    };
  },
  watch: {
    responseBg() {
      setTimeout(() => {
        this.response = "";
      }, 5000);
    },
  },
  computed: {
    hasSubscriptionExpired() {
      return this.$store.getters.hasSubscriptionExpired;
    },
    ...mapState({
      banks: (state) =>
        state.Banks.map((bank) => ({
          ...bank,
          id: bank.code,
        })),
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
    headers(){
      if(this.payrunData.type && this.payrunData.type === 'off-cycle'){
        return this.offCyclesheaders
      }

      return this.regularBackPayheaders
    },
    breadcrumbsOffCycle(){
      return [
        {
          disabled: false,
          text: "Off-Cycle Pay",
          href: "Off-CyclePay",
          id: "Off-CyclePay",
        },
        {
          disabled: false,
          text: `${this.payrunData.payType}`,
          href: `${this.payrunData.payType}`,
          id: `${this.payrunData.payType}`,
        },
        {
          disabled: false,
          text: `${this.payrunData.payrollStatus}`,
          id: `${this.payrunData.payrollStatus}`,
        },
      ]
    }
  },
  methods: {
    async canExport() {
      try {
        await this.$handlePrivilege("payRun", "exportPayrun");
        this.exportFile();
      } catch (error) {
        this.$toasted.error(
          "You do not have permission to perform this action",
          {
            duration: 5000,
          }
        );
      }
    },
    async exportFile() {
      try {
        const { data } = await this.$_exportFile(this.$route.params.id);
        const name = `${this.$DATEFORMAT(
          new Date(this.payrunData.year, this.payrunData.month - 1, 1),
          "LLLL"
        )} ${this.payrunData.year} pay cycle`;
        this.$exportFile(data, name);
        this.$toasted.success("Your file is ready...", { duration: 5000 });
      } catch (error) {
        this.$toasted.error(error, { duration: 5000 });
      }
    },
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    queryUpdate() {
      const pageNumber = this.numberOfPage
        ? `?page=${this.numberOfPage}`
        : `?page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getOnePayrun(`${pageNumber}${itemPage}`);
    },

    handleCancelModal() {
      this.showCancelModal = false;
    },
    checkPassword() {
      this.disableBtn = true;
      this.$_checkPassword({
        userId: this.$AuthUser.id,
        password: this.password,
      })
        .then(() => {
          this.showPasswordModal = false;
          this.password = "";
          this.decryptPassword = false;
          this.disableBtn = false;
          this.makePayment();
        })
        .catch(() => {
          this.$toasted.error("Password is incorrect", { duration: 5000 });
          this.disableBtn = false;
        });
    },
    handleConfirm() {
      this.confirmModal = false;
      this.showPasswordModal = true;
    },
    handleBack() {
      this.$router.back();
      /* if (this.$route.query.slug === "approval") {
        this.$router.back();
      } else {
        this.$router.push({
          name: "Pay Runs",
          query: { currentTab: this.$route.query.currentTab },
        });
      } */
    },
    handlePayOffline() {
      this.showOfflineSidebar = true;
      this.showOfflineModal = false;
    },
    payOffline() {
      this.loadingData = true;
      this.showOfflineSidebar = false;

      this.offline.payrunId = this.payrunData.id;
      this.$_payOffline(this.offline)
        .then(() => {
          this.$router.push({ name: "Pay Runs" });
          this.$toasted.success(`Payment option saved successully.`, {
            duration: 3000,
          });
        })
        .catch((err) => {
          this.loadingData = false;
          const message = err.message ? err.message : "failed.";
          this.$toasted.error(message, {
            duration: 3000,
          });
        });
    },
    cancelApproval() {
      this.showCancelModal = false;
      this.$router.push({
        name: "Pay Runs",
        query: { currentTab: this.$route.query.currentTab },
      });
    },
    async checkOption(selectedOption) {
      if (selectedOption === "Pay Offline") {
        try {
          await this.$handlePrivilege("payRun", "payOffline");
          this.showOfflineModal = true;
        } catch (error) {
          this.$toasted.error(
            "You do not have permission to perform this task",
            {
              duration: 5000,
            }
          );
        }
      } else {
        try {
          await this.$handlePrivilege("payRun", "cancelApprovedPayRun");
          this.showCancelModal = true;
        } catch (error) {
          this.$toasted.error(
            "You do not have permission to perform this task",
            {
              duration: 5000,
            }
          );
        }
      }
    },
    colorText(status) {
      switch (status) {
        case "review":
          return "text-blueCrayola";

        case "disapproved":
          return "text-desire";

        case "approved":
          return "text-mediumSeaGreen";

        case "paid":
          return "text-mediumSeaGreen";

        default:
          return "";
      }
    },

    async deleteModal(id) {
      try {
        await this.$handlePrivilege("payRun", `deletePayRunOnDraft`);
        this.delModal = true;
        this.payrunId = id;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    async edit() {
      try {
        await this.$handlePrivilege("payRun", `editPayRunOnDraft`);
        const getPayrun = {
          payrun: {},
          employees: [],
        };
        getPayrun.payrun.id = this.$route.params.id;
        getPayrun.payrun.payrollStatus = "review";

        // eslint-disable-next-line array-callback-return
        this.tableData.map((item) => {
          getPayrun.employees.push({
            id: item.id,
            reimbursements: item.reimbursements,
          });
        });

        this.$_editOnePayrun(getPayrun)
          .then(() => {
            this.$router.back();
            this.$toasted.success("Payrun updated successfully", {
              duration: 5000,
            });
          })
          .catch(() => {
            this.snackbar = true;
            this.snackbarColor = "error";
            this.snackbarText =
              "An error occured, Please contact an IT personnel";
          });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    async submitApproval(approvalStatus) {
      try {
        if (approvalStatus === "Approved") {
          this.approveRequest();
        } else if (approvalStatus === "Disapproved") {
          this.disapproveRequest();
        }
        this.$router.push({
          name: "Pay Runs",
          query: { currentTab: approvalStatus },
        });

        this.$toasted.success(`Payrun ${approvalStatus} successully.`, {
          duration: 6000,
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    deletePayrun() {
      const id = this.payrunId;
      this.$_deletOnePayrun(id).then(() => {
        this.$router.push({
          name: "Pay Runs",
          query: { currentTab: this.$route.query.currentTab },
        });
      });
    },

    async paymentPrivilege() {
      try {
        await this.$handlePrivilege("payRun", "payOnline");
        this.confirmModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    makePayment() {
      this.loadingData = true;
      this.confirmModal = false;
      this.$_makePayrunPayment({ payrunId: this.payrunData.id })
        .then(() => {
          this.$router.push({
            name: "Pay Runs",
            query: { currentTab: "Paid" },
          });
          this.$toasted.success(`Payment initiated successully.`, {
            duration: 5000,
          });
        })
        .catch((err) => {
          this.loadingData = false;
          const message = err || "Payment failed.";
          this.$toasted.error(message, {
            duration: 6000,
          });
        });
    },

    displayProgress() {
      this.seeComments = true;
    },

    approveRequest() {
      const payload = {
        orgId: this.$orgId,
        userId: this.$AuthUser.id,
        requestId: this.$route.params.id,
        initiatorId: this.approverDetails.initiatorId,
        comment: this.comment,
        requestSlug: this.approverDetails.requestSlug,
        hasApproved: true,
      };
      this.$_respondApprovalRequest(payload)
        .then((result) => {
          this.success = result.data.success;
          this.response = result.data.message;
          if (this.success) {
            this.responseBg = "bg-mediumSeaGreen";
          } else {
            this.responseBg = "bg-desire";
          }
          this.requestModal = false;
        })
        .catch((err) => {
          throw new Error(err);
        });
    },

    disapproveRequest() {
      const payload = {
        orgId: this.$orgId,
        userId: this.$AuthUser.id,
        requestId: this.$route.params.id,
        initiatorId: this.approverDetails.initiatorId,
        comment: this.comment,
        requestSlug: this.approverDetails.requestSlug,
        hasApproved: false,
      };
      this.$_respondApprovalRequest(payload)
        .then((result) => {
          this.success = result.data.success;
          this.response = result.data.message;
          if (this.success) {
            this.responseBg = "bg-mediumSeaGreen";
          } else {
            this.responseBg = "bg-desire";
          }
          this.requestModal = false;
        })
        .catch((err) => {
          throw new Error(err);
        });
    },

    getAllRequests() {
      const userId = this.$AuthUser.id;
      const params = "";
      this.$_getMyApprovalsfilter(params, userId)
        .then((result) => {
          this.loading = false;
          const inProgressData = result.data.approvals;
          inProgressData.filter((a) => {
            console.log(a);
            if (a.requestId === this.$route.params.id) {
              this.approvalsData = a;
            }
            return {};
          });
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },

    getApprovalTurn() {
      this.$_getApprovalProgress(this.$route.params.id)
        .then((result) => {
          const approverDetails = result.data.data;
          this.requestProgress = result.data.data;
          approverDetails.filter((a) => {
            if (
              a.requestId === this.$route.params.id &&
              a.currentApprover === "active"
            ) {
              this.approverDetails = a;
              this.approversNumber = approverDetails.length;
            }
            return {};
          });
        })
        .catch((err) => {
          throw new Error(err);
        });
    },

    getOnePayrun(params) {
      this.$_getOnePayrun(this.$route.params.id, params).then((result) => {
        const { employees, ...payrun } = result.data.payrun;
        this.metaData = result.data.payrun.meta;
        this.payment = result.data.payment;
        this.payrunData = payrun;
        this.payrunTrend = result.data.payrun.payrunTrend;
        this.getGrossNetTrend(employees)

        this.tableData = employees.map((emp) => ({
          ...emp,
          status: payrun.payrollStatus,
        }));

        if (employees) {
          employees.forEach((val) => {
            this.netPaySum += val.netPay;
          });
        }
        this.payCycleMonth = result.data.payrun.month;
        this.payCycleYear = result.data.payrun.year;
        this.isPresent = true;

        if (this.payrunData.payrollStatus === "review") {
          this.getApprovalTurn();
          // this.getAllRequests();
        }
      });

      this.loadingData = false;
    },

    getGrossNetTrend(employees){
      this.selectedEmployees = employees.length;

      // Use reduce to calculate totalNetPay and employeeGrossPay
      const { totalNetPay, employeeGrossPay, orgPension, paye, employeePension, nhfAmount } =
      employees.reduce((acc, element) => {
        if (element.netPay) {
          acc.totalNetPay += element.netPay;
          acc.employeeGrossPay += element.grossEarnings;
          acc.orgPension += element.orgPension;
          acc.paye += element.paye;
          acc.employeePension += element.employeePension;
          acc.nhfAmount += element.nhfAmount;
        }
        return acc;
      }, { totalNetPay: 0, employeeGrossPay: 0, orgPension: 0, paye: 0, employeePension: 0, nhfAmount: 0 });

      this.totalNetPay = totalNetPay;
      this.employeeGrossPay = employeeGrossPay;
      this.orgPension = orgPension;
      this.paye = paye;
      this.employeePension = employeePension;
      this.nhfAmount = nhfAmount;

       // Update current payrun data
      this.payrunTrend.currentPayrun.grossPay = employeeGrossPay;
      this.payrunTrend.currentPayrun.netPay = totalNetPay;
      this.payrunTrend.currentPayrun.empCount = this.selectedEmployees;

      // Calculate variance for grossPay, netPay, and empCount
      const { previousPayrun, currentPayrun, variance } = this.payrunTrend;
      variance.grossPay = currentPayrun.grossPay - previousPayrun.grossPay;
      variance.netPay = currentPayrun.netPay - previousPayrun.netPay;
      variance.empCount = currentPayrun.empCount - previousPayrun.empCount;

      // Determine trend direction for each field
      this.growthTrend = this.calculateTrend(variance);
    },

    // Helper function to calculate trend direction
    calculateTrend(variance) {
      return {
        grossPay: variance.grossPay > 0 ? 'upward' : variance.grossPay < 0 ? 'downward' : 'no change',
        netPay: variance.netPay > 0 ? 'upward' : variance.netPay < 0 ? 'downward' : 'no change',
        empCount: variance.empCount > 0 ? 'upward' : variance.empCount < 0 ? 'downward' : 'no change'
      };
    },

    approveDetails(value) {
      if (value === "hasApproved") {
        return "border-color: #13B56A;";
      }
      if (value === "active") {
        return "border-color: #E99323;";
      }
      if (value === "inactive") {
        return "border-color: #333333;";
      }
      return "";
    },
    approveText(value) {
      if (value === "hasApproved") {
        return "Approved";
      }
      if (value === "active") {
        return "Pending";
      }
      if (value === "inactive") {
        return "Not Started";
      }
      return "";
    },
    approveBgColor(value) {
      if (value === "hasApproved") {
        return "text-mediumSeaGreen bg-light-sea-green";
      }
      if (value === "active") {
        return "text-carrotOrange bgCarrot";
      }
      if (value === "inactive") {
        return "text-black bg-gray-200";
      }
      return "";
    },
    handleCloseTotalGross(){
      this.isTotalGross = false
    },
    async getBanks() {
      await this.$store.dispatch("getBanks");
    },
  },

  mounted() {
    this.getBanks();
    this.getToday = String(new Date().getDate()).padStart(2, "0");

    // approved, draft, disapproved, paid, review
    this.getOnePayrun("?page=1&perPage=50");
  },
};
</script>

<style scoped>
.bgCarrot {
  background-color: rgba(233, 147, 35, 0.08);
}
.widthInherited {
  width: inherit;
}
.buttons {
  width: 126px;
  height: 44px;
  border-radius: 5px;
}
.grosspay {
  background: #2176ff26;
  padding: 0.3rem 0.7rem;
  color: #2176ff;
  border-radius: 5px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.w-204{
  flex: 1 1 calc(100% / 7) !important;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr)); /* Responsive columns */
    gap: 1rem; /* Gap between the grid items */
}

.text-10{
  font-size: 10px !important
}
</style>
